// core
import { mapGetters } from 'vuex';

// components
import Layout from '@/Views/_Layout/index.vue';
import HeaderSmall from '@/Views/_Components/Header/Small/index.vue';
import InterseptedImage from '@/Views/_Components/Helpers/InterseptedImage/index.vue';
import HelpMenu from '@/Views/_Components/HelpMenu/index.vue';

// skeleton
import Skeleton from '@/Views/_Skeleton/Category/index.vue';

// helpers
import { slugify } from '@/Helpers/Misc';

// icons
import { HeartIcon, PlusCircleIcon } from 'vue-feather-icons';

export default {
	name: 'Category',

	components: {
		'oston-layout': Layout,
		'oston-header-small': HeaderSmall,
		'oston-help-menu': HelpMenu,
    'oston-intersepted-image': InterseptedImage,

    // skeleton
    'skeleton-category': Skeleton,

		// icons
		'feather-icon-heart': HeartIcon,
		'feather-icon-plus': PlusCircleIcon
	},

	mounted() {
		const { id } = this.$route.params;

		if (id !== this.category.id) {
			_.controller('category').get(id);
		}
	},

	methods: {
		slugify,
    select: function(id) {
      _.controller('magazine').get(id);
    }
	},

	computed: mapGetters({
		category: 'CategoryModel/category',
		title: 'CategoryModel/title',
		loading: 'LoadingModel/loading'
	})
};
